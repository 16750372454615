<template>
  <v-card elevation="0" :loading="chartLoading" height="240">
    <v-card-text>
      <chart height="211" :options="chart.options" :series="chart.series" />
    </v-card-text>
  </v-card>
</template>

<script>
  /**
   * @author Ali Hüseyin ŞİRELİ
   * @email ali.sireli@globme.com.tr
   * @create date 2021-03-31 15:37:07
   * @modify date 2021-03-31 15:37:07
   * @desc TimesheetChart
   */
  export default {
    name: "TimesheetChart",
    props: {
      workplans: Array,
      chartLoading: Boolean
    },
    data: vm => ({
      chart: {
        options: {
          chart: {
            type: "donut",
            width: "100%"
          },
          dataLabels: {
            enabled: false
          },
          plotOptions: {
            pie: {
              customScale: 1,
              donut: {
                size: "40%"
              },
              offsetY: 10
            },
            stroke: {
              colors: undefined
            }
          },
          title: {
            text: vm.$t("dashboard.access.monthly_timesheet_report"),
            style: {
              fontSize: "18px"
            }
          },
          colors: ["#ff0000", "#c0392b", "#e74c3c", "#27ae60", "#2ecc71", "#3498db", "#9da8b0"],
          labels: [
            vm.$t("dashboard.access.absence"),
            vm.$t("dashboard.access.incalculable"),
            vm.$t("dashboard.access.lates"),
            vm.$t("dashboard.access.overtime"),
            vm.$t("dashboard.access.early_leaver"),
            vm.$t("dashboard.access.missing_time"),
            vm.$t("dashboard.access.expected")
          ],
          legend: {
            position: "left",
            offsetY: 50,
            fontSize: "11px"
          }
        },
        series: []
      }
    }),
    watch: {
      workplans: {
        deep: true,
        handler(workplans) {
          this.renderChart(workplans);
        }
      }
    },
    methods: {
      renderChart(workplans) {
        const workplansBefore = workplans.filter(x =>
          this.$moment(x.date).isBefore(
            this.$moment()
              .add(1, "day")
              .format("YYYY-MM-DD")
          )
        );
        let absences = workplansBefore.filter(
          item => !item.countAsOvertime && item.assignedShifts.length && !item.leaveRequest && !item.timesheets.length
        );
        let timesheets = workplansBefore.filter(
          item => !item.countAsOvertime && item.assignedShifts.length && !item.leaveRequest && item.timesheets.length
        );
        let incalculables = [],
          overtimes = [],
          earlies = [],
          missingTimes = [],
          lates = [],
          expected = [];
        expected = new Array(workplans.length - workplansBefore.length);
        timesheets.forEach(workplan => {
          let incalculable = workplan.timesheets.filter(
            item => item.clockInTimestamp !== null && item.clockOutTimestamp === null
          );

          let lately = workplan.timesheets.filter(item => item.latelyClockInTimeInMinutes);
          let overtime = workplan.timesheets.filter(item => item.totalOvertimeInMinutes);
          let missingTime = workplan.timesheets.filter(item => item.totalMissingTimeInMinutes);
          let early = workplan.timesheets.filter(item => item.earlyClockOutTimeInMinutes);
          if (incalculable.length) incalculables.push(incalculable);
          if (lately.length)
            lates.push({
              date: workplan.date,
              totalMinutes: lately.reduce((acc, item) => acc + item.latelyClockInTimeInMinutes, 0) / lately.length
            });
          if (overtime.length) {
            overtimes.push({
              date: workplan.date,
              totalMinutes: overtime.reduce((acc, item) => acc + item.totalOvertimeInMinutes, 0) / early.length
            });
          }
          if (missingTime.length) missingTimes.push(missingTime);
          if (early.length)
            earlies.push({
              date: workplan.date,
              totalMinutes: early.reduce((acc, item) => acc + item.earlyClockOutTimeInMinutes, 0) / early.length
            });
        });
        this.chart.series = [
          absences.length,
          incalculables.length,
          lates.length,
          overtimes.length,
          earlies.length,
          missingTimes.length,
          expected.length
        ];
      }
    },
    created() {
      this.renderChart(this.workplans);
    }
  };
</script>

<style></style>
